import { useQuery } from '@apollo/client';
import { Empty, Typography } from 'antd';
import { GET_ALL_ARTICLES } from 'api/queries';
import { BackToPage, ArticlePreview, PrimaryButton, Loader } from 'components';
import { Col, Container, h3h, Row } from 'Design';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BREAKPOINTS } from 'utils';

export const NewsPage = () => {
  const [limitDesktop, setLimitDesktop] = useState(6);
  const [limitMobile, setLimitMobile] = useState(5);
  const { loading, data, error } = useQuery(GET_ALL_ARTICLES);

  if (loading) {
    return (
      <Wrapper
        maxWidth={1180}
        paddingTop={116}
        paddingX={32}
        xlPaddingTop={112}
        mdPaddingX={16}
        smPaddingTop={96}
      >
        <BackToPage text='Назад на главную' link='/' />
        <Title>Новости</Title>
        <Row justifyContent={'center'} wide alignCenter height={'160px'}>
          <Loader />
        </Row>
      </Wrapper>
    );
  } else if (error) {
    return (
      <Wrapper
        maxWidth={1180}
        paddingTop={116}
        paddingX={32}
        xlPaddingTop={112}
        mdPaddingX={16}
        smPaddingTop={96}
      >
        <BackToPage text='Назад на главную' link='/' />
        <Title>Новости</Title>
        <Empty description={<Typography.Text>Пусто</Typography.Text>} />
      </Wrapper>
    );
  }

  const articles = data.articles?.data;

  let counterRightCol = 1;
  let counterLeftCol = 4;

  const isBig = (number) => {
    return number % 3 === 0 ? true : false;
  };

  const rightCol = (item, index) => {
    let article = '';
    if (counterRightCol === index && isBig(index)) {
      counterRightCol += 4;
      article = (
        <div key={index}>
          <ArticlePreview big data={item} />
        </div>
      );
    } else if (counterRightCol === index) {
      counterRightCol++;
      article = (
        <div key={index}>
          <ArticlePreview data={item} />
        </div>
      );
    }

    return article;
  };

  const leftCol = (item, index) => {
    let article = '';
    if (counterLeftCol === index && isBig(index)) {
      counterLeftCol += 4;
      article = (
        <div key={index}>
          <ArticlePreview big data={item} />
        </div>
      );
    } else if (counterLeftCol === index) {
      counterLeftCol++;
      article = (
        <div key={index}>
          <ArticlePreview data={item} />
        </div>
      );
    }

    return article;
  };

  const showMoreDesktop = () => {
    setLimitDesktop((prev) => prev + 6);
  };

  const showMoreMobile = () => {
    setLimitMobile((prev) => prev + 5);
  };

  return (
    <Wrapper
      maxWidth={1180}
      paddingTop={116}
      paddingX={32}
      xlPaddingTop={112}
      mdPaddingX={16}
      smPaddingTop={96}
    >
      <BackToPage text='Назад на главную' link='/' />
      <Title>Новости</Title>

      <ContainerDesktop lgHidden>
        <StyledRow nowrap>
          <StyledCol>
            <div>
              <ArticlePreview big data={articles[0]} />
            </div>
            {articles
              .slice(0, limitDesktop)
              .map((item, index) =>
                index > 1 && index === counterLeftCol
                  ? leftCol(item, index)
                  : ''
              )}
          </StyledCol>
          <StyledCol>
            {articles
              .slice(0, limitDesktop)
              .map((item, index) =>
                index !== 0 && index === counterRightCol
                  ? rightCol(item, index)
                  : ''
              )}
          </StyledCol>
        </StyledRow>

        {limitDesktop > articles.length ? null : (
          <Row justifyContent='center' marginTop={60}>
            <PrimaryButton
              onClick={() => showMoreDesktop()}
              title='Смотреть еще'
              large
            />
          </Row>
        )}
      </ContainerDesktop>
      <ContainerMobile hidden lgVisible>
        {articles.slice(0, limitMobile).map((item, index) => (
          <div key={index}>
            <ArticlePreview data={item} />
          </div>
        ))}

        {limitMobile > articles.length ? null : (
          <Row justifyContent='center' marginTop={60}>
            <PrimaryButton
              onClick={() => showMoreMobile()}
              title='Смотреть еще'
              large
            />
          </Row>
        )}
      </ContainerMobile>
    </Wrapper>
  );
};

const Wrapper = styled(Container)`
  margin: 0 auto;
  box-sizing: content-box;
`;

const ContainerDesktop = styled(Container)``;

const ContainerMobile = styled(Container)`
  & > div {
    margin-bottom: 30px;
  }
`;

const StyledRow = styled(Row)`
  gap: 60px;
`;

const StyledCol = styled(Col)`
  & > div {
    margin-bottom: 40px;
  }
`;

const Title = styled.h3`
  margin-top: 40px;
  margin-bottom: 60px;
  ${h3h}
  @media (max-width:${BREAKPOINTS.sm}) {
    margin-top: 30px;
    margin-bottom: 48px;
    font-size: 24px;
    line-height: 29px;
  }
`;
